export default {
  signup: '/auth/signup',
  affiliateSignup: '/auth/affiliate-signup',
  login: '/auth/login',
  agencyLogin: '/auth/agency-login',
  sendPasswordlessCode: '/auth/passwordless-send-verification-code',
  validatePasswordlessCode: '/auth/passwordless-validate-code',
  sendVerificationCode: '/auth/send-verification-code',
  passwordResetWithCode: '/auth/password-reset-with-code',
  userExist: '/auth/user-exists',
  socialLogin: '/auth/social-login',
  refresh: '/auth/refresh-token',
  agencySignup: '/auth/agency-signup',
  assignAgenctoUser: '/agency-team-member/assign-agency-to-user',
  agentSignup: '/auth/agent-signup',
  resetPassword: '/auth/reset-password',
  changePassword: '/auth/change-password',
  updatePassword: '/auth/update-password',
  confirmEmail: '/auth/confirm-email',
  user: '/auth/user',
  editUserProfileInfo: '/auth/edit-profile-info',
  listAirports: '/flight/airports',
  search: '/search-proxy',
  favorite: '/dashboard/favorite',
  getId: '/get-id',
  eventsProxy: '/te/proxy',
  v2EventsByCategory: '/te/v2/event-by-category',
  teOrder: '/te/order',
  teTax: '/te/tax',
  getHeaderTeams: '/teams',
  getFollowedEvents: '/get-followed-events',
  geocodeAws: '/aws-places/geocode',
  getLatLngAws: '/aws-places/get-lat-lng',
  getUtcOffset: '/google-api/get-utc-offset',
  travelPro: '/booking',
  travelProPost: '/booking/post',
  propertySearch: '/property/search',
  propertyDetails: '/property/details',
  propertyPriceCheck: '/property/price-check',
  holdPropertyBooking: '/property/hold-property-booking',
  resumePropertyBooking: '/property/resume-property-booking',
  cancelPropertyBooking: '/property/cancel-property-booking',
  createOrder: '/payment',
  pay: '/payment/pay',
  completePayment: '/transaction/moneris',
  userOrders: '/order/user-orders',
  adminOrders: '/order/admin-orders',
  updateOrderStatus: '/order/update-status',
  addOrderComment: '/order-comment',
  addLocation: '/user/add-location',
  updateHotelOrderStatus: '/order/update-hotel-status',
  updateOrderTicketStatus: '/order/update-ticket-status',
  updateHotelStatus: '/hotel/update-status',
  updateFlightStatus: '/flight/update-booking-status',
  createEstOrder: '/order',
  banner: '/banner',
  banners: '/banner/banners',
  localRefresh: '/auth/refresh',
  emergencyNumber: '/emergency-number',
  orderDetails: '/order/details',
  orderLogs: '/order-log',
  hotelOrderLogs: '/order-log/hotel',
  ticketOrderLogs: '/order-log/ticket',
  cancelOrderItinerary: '/order/cancel-order-itinerary',
  itinerary: '/property/itinerary',
  usersList: '/user/list',
  updateUserRole: '/user/change-role',
  addUser: '/user/add-user',
  resendPassword: '/user/resend-password',
  deleteAccount: '/user/delete-account',
  uploadProfileImage: '/user/upload-profile-image',
  deleteUser: '/user/admin-delete',
  cacheLogs: '/property/cache-logs',
  cacheUpdate: '/property/update-cache',
  ordersReport: '/report/orders',
  usersReport: '/report/users',
  postVisit: '/report/visit',
  xmlSitemap: '/xml/sitemap',
  reportError: '/sendgrid/report-error',
  logOperation: '/operation-log',
  shareOffer: '/sendgrid/share-offer',
  resendConfirmation: '/user/resend-confirmation',
  placeGeocode: '/places/geocode',
  placesLatLng: '/places/lat-lng',
  adminDiscountCodes: '/discount-code/admin-discount-codes',
  discountCode: '/discount-code',
  checkDiscountCode: '/discount-code/validate',
  addDiscountCode: '/discount-code/create',
  addDiscountCodeComment: '/discount-code/comment',
  affiliate: '/affiliate',
  adminPatchAffiliate: '/affiliate/admin',
  getAffiliateData: '/affiliate/me',
  affiliateSiteVisit: '/affiliate-site-visit',
  affiliateReferral: '/affiliate-referral',
  getAffiliateSalesData: '/affiliate-referral/me',
  getAffiliateSiteVisits: '/affiliate-site-visit/me',
  getPartnerSales: '/partner/sales',
  getPartnerPerformances: '/partner/performances',
  affiliateDiscountCodes: '/discount-code/affiliate',
  contactUs: '/sendgrid/contact-us',
  concertsLanding: '/concerts',

  affiliateAdminId: (id: any) => `/affiliate/admin/${id}`,

  // newsletter
  addTagToUser: '/newsletter/add-tag-to-user',
  removeTagFromUser: '/newsletter/remove-tag-from-user',

  // Travelers
  traveler: '/traveler',
  travelerId: (travelerId: number) =>
    `/traveler/${travelerId}?travelerId=${travelerId}`,
  getUserTravelers: '/traveler/user-travelers',

  // AGENCY
  agencyMe: '/agency/me',
  agency: '/agency',
  agencyPerformance: (agencyId: number) => `/agency/${agencyId}/performance`,
  agencyDetailedPerformance: (agencyId: number) =>
    `/agency/${agencyId}/detailed-performance`,
  agencyReports: (agencyId: number) => `/report/agency/${agencyId}/orders`,
  agencyDetails: (agencyId: number) => `/agency/${agencyId}/details`,
  agencySales: (agencyId: number) => `/agency/${agencyId}/sales`,
  agencyAffiliatesCodes: (agencyId: number) =>
    `/agency/${agencyId}/affiliate-codes`,
  agencyDiscountCode: (agencyId: number, codeId) =>
    `/agency/${agencyId}/discount-code/${codeId}`,
  agencyTeamMember: (agencyId: any) => `/agency-team-member/${agencyId}`,
  agencyTeamMemberInvite: (agencyId: number) =>
    `/agency-team-member-invites/${agencyId}`,
  agentDetailsUnderAgency: ({ agencyId, agentUserId }) =>
    `/agency/${agencyId}/agent/${agentUserId}/details`,
  agencyAdmin: '/agency/admin',

  // ANALYTICS
  track: '/analytics/track',
  identifyUser: '/analytics/identify-user',

  // ANONYMOUS USER
  createAnonymousUser: '/anonymous-user',
  updateAnonymousUser: (anonymousUserId: string) =>
    `/anonymous-user/${anonymousUserId}`,

  // Shopping cart
  shoppingCart: '/shopping-cart',
  shoppingCartId: (cartId: any) =>
    `/shopping-cart/${cartId}`,
};

export const serverApiPrefix = (path: string) => `/api/v1${path}`;
export const frontApiPrefix = (path: string) => `/api${path}`;
